(<template>
  <article class="auto-join">
    <tt-progress />
  </article>
</template>)

<script>
  export default {
    mounted() {
      const {token, uid} = this.$route.query;
      if (token && uid) {
        const params = {token, uid};
        this.$store.dispatch('verifyEmail', params).then((response) => {
          if (response.data && response.data.token) {
            const token = response.data.token;
            this.$store.dispatch('TTAuthStore/logOut').then(() => {
              this.$store.dispatch('TTAuthStore/logIn', {token: token, uid: uid});
              this.$store.commit('UserInfoStore/setUserInfo', {userInfoObj: {uid: uid}});
              this.$store.commit('TTAuthStore/setAuthInfoToStorage', {token: token, uid: uid});
              this.$store.dispatch('UserInfoStore/fetchUserInfo', uid).then(() => {
                this.$router.replace(this.$makeRoute({name: 'SignIn'}));
              });
            });
          } else {
            this.$store.dispatch('UserInfoStore/fetchUserInfo', uid).then(() => {
              this.$router.replace(this.$makeRoute({name: 'SignIn'}));
            });
          }
        }).catch(() => {
          this.$router.replace(this.$makeRoute({name: 'SignIn'}));
        });
      } else {
        this.$router.replace(this.$makeRoute({name: 'SignIn'}));
      }
    }
  };
</script>

<style scoped>
  .auto-join {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
</style>
